<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'App',
};
// docker run -it -p 8082:8080 --rm -v $(pwd):/srv -w /srv node:10.16.3 npm run serve
</script>


<style>
.card-shadow-primary {
	box-shadow: 2px 4px 12px #00000014 !important;
}
</style>