import api from '@/plugins/api';

const state = {
    limnimeters: [],
    channels: [],
    isLoading: false,
    limnimeterEdit: {}
};

const actions = {
    loadLimnimeters({ commit }) {
        return new Promise(resolve => {
            api().get(`/limnimeters`)
                .then(res => {
                    commit('setLimnimeters', res.data);
                    resolve();
                })
        })
    },
    loadChannels({ commit }) {
        return new Promise(resolve => {
            api().get(`/channels`).then(res => {
                commit('setChannels', res.data)
                resolve()
            })
        })
    },

    saveLimnimeter({ commit }, payload) {
        commit('setLoading', true)
        return new Promise((resolve) => {
            api()
                .post(`/limnimeters`, payload)
                .then(() => {
                    resolve();
                })
                .finally(() =>
                    commit('setLoading', false))
        })
    },
    getLimnimeter({ dispatch, commit }, payload) {
        dispatch('loadChannels')
        payload.curve.min = payload.curve[0].medicion
        payload.curve.max = payload.curve[payload.curve.length - 1].medicion
        payload.observation = payload.observation ?? null
        commit('setLimnimeterEdit', payload)
    },
    updateLimnimeter({ commit }, payload) {
        commit('setLoading', true)
        return new Promise((resolve) => {
            api().put(`limnimeters/${payload.id}`, payload).then(() => {
                resolve()
            }).finally(() => {
                commit('setLoading', false)
            })
        })
    },
    deleteLimnimeter({ commit }, payload) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            api()
                .delete(`limnimeters/${payload}`)
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error('Hubo el un problema con la operacion:', error);
                    reject(error);
                }).finally(() => {
                    commit('setLoading', false)

                })
        });
    }
};

const mutations = {
    setLimnimeters(state, data) {
        state.limnimeters = data
    },
    setChannels(state, data) {
        state.channels = data
    },
    setLoading(state, data) {
        state.isLoading = data
    },
    setLimnimeterEdit(state, data) {
        state.limnimeterEdit = data
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
